import { ServiceDescriptor } from "./ServiceInfrastructure/ServiceDescriptor";
import { ServiceEnvironment } from "./ServiceInfrastructure/ServiceEnvironment";
import { ServiceProxy } from "./ServiceInfrastructure/ServiceProxy";


function fetchServiceMetadata(name): ServiceDescriptor {
    if (name.substring(0, 1) != "/")
        name = "/" + name;

    var appid = ServiceEnvironment.AppId;
    var url = '/metades.web.core/ajax-meta-service/' + appid + name;
    var result = $.ajax({
        async: false,
        type: "POST",
        contentType: "application/json",
        url: url
    });
    return JSON.parse(result.responseText);
}


/**
 * The new service objects (metades.web.core.ts) work with promise. We need to wrap it 
 * in a jQuery Deferred in order to be backwards compatible. 
 */
function CreateLegacyServiceObject (serviceUrl: string, meta: ServiceDescriptor): any {
    var proxy = new ServiceProxy(serviceUrl);

    var result = { };
    for (var i = 0; i < meta.methods.length; i++) {
        (function (i) {
            var mname = meta.methods[i];
            result[mname] = function () { 
                var rs = jQuery.Deferred();
                proxy.call(mname, Array.prototype.slice.call(arguments)).then(value => rs.resolve(value), err => rs.fail(err)); 
                return rs;
            }
        })(i);
    }
    for (var i = 0; i < meta.events.length; i++) {
        (function (i) {
            var mname = meta.events[i];
            result[mname] = function (callback) { 
                var rs = jQuery.Deferred();
                proxy.addevent(mname, callback).then(value => rs.resolve(value), err => rs.fail(err));
                return rs;
            }
        })(i);
    }

    result["Dispose"] = function () {
        proxy.dispose();
    };

    return result;
}


function mwc_getservice(name: string): any
{
    var meta = fetchServiceMetadata(name);
    var service = CreateLegacyServiceObject(name, meta);
    return service;
}

window["mwc_getservice"] = mwc_getservice;